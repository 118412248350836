import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const carousels = [
  "https://ulipsutest-cdn.ulipsu.com/explore/web/Web_IA.jpg",
  "https://ulipsutest-cdn.ulipsu.com/explore/web/Web_Project.jpg",
];

const HomeCarousel = () => {
    // TODO: carousel should be continues
    return <div className="bg-surface-element radius6 p3 elevation4">
        {
            <Carousel showArrows={false} autoPlay={true} interval={5000} showThumbs={false} infiniteLoop={true}>
                {
                    carousels.map(e => <div
                        key={e}
                        className="radius3 depth4 d-flex align-center justify-center radius4"
                        style={{ background: '#FBDBA9', aspectRatio: '1440 / 328', overflow: 'hidden' }}
                    >
                        <img src={e} alt='carousel Image' />
                    </div>)
                }
            </Carousel>
        }
    </div >;
}

export default HomeCarousel;
