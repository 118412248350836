import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

// 23 Apr 24
export const getFormattedDate = (date) => new Date(date).toLocaleString('default', {
    month: 'short',
    day: '2-digit',
    year: '2-digit'
});

// 23 Apr 2024
export const getFullFormattedDate = (date) => new Date(date).toLocaleString('default', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
});

export const getFormattedTime = (date) => new Date(date).toLocaleTimeString('default', {
    timeStyle: 'short',
    hourCycle: 'h12'
});

export const youtubeEmbedURL = (url) => {
    if (url === null || url === undefined) {
        return 'https://ulipsu.com/';
    }
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|live\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    const match = url.match(youtubeRegex);
    if (match) {
        const videoId = match[1]; 
        return `https://www.youtube.com/embed/${videoId}`;  
    }
    return url;
}

export const canJoinEvent = (date) => {
    var currentDate = new Date();
    var joinDate = new Date(date);
    var diff = currentDate - joinDate;
    let minutes = Math.floor(diff / 1000 / 60);
    return minutes >= -10 && minutes <= 15;
}

export const isEventStarted = (date) => {
    var currentDate = new Date();
    var joinDate = new Date(date);
    var diff = currentDate - joinDate;
    let minutes = Math.floor(diff / 1000 / 60);
    return minutes >= -10;
}

export const isEventEnded = (date, duration) => {
    var currentDate = new Date();
    var eventData = new Date(date);
    var diff = currentDate - eventData;
    let minutes = Math.floor(diff / 1000 / 60);
    var d = convertToMinuesFromTime(duration);
    return minutes >= d;
}

function convertToMinuesFromTime(duration) {
    var timeParts = String(duration).split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
}

export const stringToFormattedDate = (date) => {
    var newDate = new Date(date);
    return newDate.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })
}

// group by list of object by given key
export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

// get file extension
// eslint-disable-next-line
export const getFileExtension = (url) => url.split('.').pop().split(/\#|\?/)[0];

// get dashboard date
export function getDashboardDate(dateObj = new Date()) {
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${day}/${month}/${String(year).slice(-2)}`;
}

// get formatted date
export function getPostFormattedDate(dateObj = new Date()) {
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return year + '-' + month + '-' + day;
}

// download to excel
export function downloadTableAsCsv(table_id, separator = ',') {
    var rows = document.querySelectorAll('table#' + table_id + ' tr');
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll('td, th');
        for (var j = 0; j < cols.length; j++) {
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            data = data.replace(/"/g, '""');
            row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
    }
    var csv_string = csv.join('\n');
    var filename = new Date().toISOString() + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
// download csv with generated table
export function downloadTableAsCsvWithGeneratedTable(pathname, lists) {
    var body = lists.map(e => {
        var obj = [...Object.values(e)];
        obj.splice(2, 1);
        return obj
    });
    if(pathname.includes('user-stat')) {
        body.unshift(['Grade', 'Section', 'Active', 'Fairly Active', 'Inactive']);
    } else {
        body.unshift(['Grade', 'Section', 'Logged In', 'Yet To Login In']);
    }
    console.log(body);
    var csv = [];
    for (var i = 0; i < body.length; i++) {
        var row = [], cols = body[i];
        for (var j = 0; j < cols.length; j++) {
            var data = cols[j].replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            data = data.replace(/"/g, '""');
            row.push('"' + data + '"');
        }
        csv.push(row.join(','));
    }
    console.log(csv);
    var csv_string = csv.join('\n');
    var filename = new Date().toISOString().replace('.','') + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

// download table
export function downloadPDF(tableId) {
    var doc = new jsPDF('p', 'pt', 'a4');
    autoTable(doc, { html: '#' + tableId })
    doc.save(`${new Date().toISOString()}.pdf`);
}

// download pdf with custom generated table
export function downloadPDFWithGeneratedtable(pathname, data) {
    var doc = new jsPDF('p', 'pt', 'a4');
    const body = data.map(e => {
        var obj = [...Object.values(e)];
        obj.splice(2, 1);
        return obj
    });
    const columnStyles = {
        0: { cellAlign: 'center' },
        1: { cellAlign: 'center' },
        2: { cellAlign: 'center' },
        3: { cellAlign: 'center' },
        4: { cellAlign: 'center' },
    };
    const headers = pathname.includes('user-stat') ? ['Grade', 'Section', 'Active', 'Fairly Active', 'Inactive'] : ['Grade', 'Section', 'Logged In', 'Yet To Login In'];
    autoTable(doc, { head: [headers], body: body, columnStyles: columnStyles });
    doc.save(`${new Date().toISOString()}.pdf`);
}

// object is emplty given excluded keys
export function isObjectEmptyExcludingKeys(obj, excludedKeys = ['student_name', 'user_id', 'class', 'section']) {
    const filteredObj = Object.fromEntries(
        Object.entries(obj).filter(([key]) => !excludedKeys.includes(key))
    );
    return Object.keys(filteredObj).length === 0 || Object.values(filteredObj).every(val => val === null || val === 0 || val === '0' || val === 'null' || val === undefined || typeof val === 'undefined');
}

// // rearrange first three ranks 
export function rearrangeFirstThreeRank(arr) {
    if (arr.length <= 0) return [];
    var res = [];
    const second = arr.find(a => a['rank_no'] === '2' || a['rank_no'] === 2);
    const first = arr.find(a => a['rank_no'] === '1' || a['rank_no'] === 1);
    const three = arr.find(a => a['rank_no'] === '3' || a['rank_no'] === 3);
    if (second) res.push(second)
    if (first) res.push(first)
    if (three) res.push(three);
    return res;
}

// convert to percentage
export function convertToPercentage(num) {
    var base = 10;
    if (num > 10 && num < 100) base = 100;
    else if (num > 100 && num < 1000) base = 1000;
    else if (num > 1000 && num < 10000) base = 10000;
    else if (num > 10000 && num < 100000) base = 100000;
    else if (num > 100000 && num < 1000000) base = 1000000;
    else if (num > 1000000 && num < 10000000) base = 10000000;
    else if (num > 10000000 && num < 100000000) base = 100000000;
    const per = (num / base) * 100;
    return Number(per.toFixed(2));
}

// remove duplicate grades
export const removeDuplicatesGrade = (arr) => {
    let unique = [];
    for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        const exist = unique.find(e => e['grade'] === element['grade']);
        if (!exist) unique.push(element);
    }
    return unique;
}

// remove duplicate grades & mass
export const removeDuplicatesGradeMass = (arr, filter = ['grade', 'is_mass']) => {
    return arr.filter((obj1, i, arr) =>
        arr.findIndex(obj2 =>
            filter.every(key => obj2[key] === obj1[key])
        ) === i
    )
}

// get network connection status
export function getNetworkConnection() {
    return (
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection ||
        null
    );
}

// remove duplicate object by id
export function removeDuplicatesById(array) {
  const uniqueIds = new Set();
  const result = [];
  for (const obj of array) {
    const id = obj.id;
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id);
      result.push(obj);
    }
  }
  return result;
}

// remove duplicate object with similar ids
export function removeSimilarDuplicatesById(array, keepIdEnding) {
  const uniqueIds = new Set();
  const result = [];
  for (const obj of array) {
    const id = obj.id;
    if (
      id &&
      typeof id === "string" &&
      !uniqueIds.has(id) &&
      id.endsWith(keepIdEnding)
    ) {
      uniqueIds.add(id);
      result.push(obj);
    }
  }
  return result;
}

// enter/exit full screen
export const toggleFullScreen = (iframeRef) => {
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    if (iframeRef.current.requestFullscreen) {
      iframeRef.current.requestFullscreen();
    } else if (iframeRef.current.mozRequestFullScreen) {
      iframeRef.current.mozRequestFullScreen();
    } else if (iframeRef.current.webkitRequestFullScreen) {
      iframeRef.current.webkitRequestFullScreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
};