import { useSelector } from "react-redux";
import LessonVideoPlayer from "./lesson-video-player";
import OtherLessonPlayer from "./other-lesson-player";
import {
  lessonsReducer,
  playingLessonReducer,
  selectedGradeReducer,
  selectedSectionReducer,
} from "../../redux/reducers";
import ToastMessage from "../toast-message";
import useNetworkHook from "../../hooks/use-network";
import { useEffect, useState } from "react";

const LessonPlayer = () => {
  const lesson = useSelector(playingLessonReducer);
  const lessons = useSelector(lessonsReducer);
  const grade = useSelector(selectedGradeReducer);
  const section = useSelector(selectedSectionReducer);
  const [toast1, setToast1] = useState(false);

  const networkState = useNetworkHook();
  const { rtt } = networkState;

  useEffect(() => {
    var res = (rtt ?? 0) > 500;
    setToast1(res);
  }, [rtt]);
  return (
    <div className="classroom__video elevation3 p3 radius6">
      {lessons.length > 0 ? (
        lesson !== null ? (
          lesson.fileType === "video" ? (
            <LessonVideoPlayer grade={grade} section={section} />
          ) : (
            <OtherLessonPlayer grade={grade} section={section} />
          )
        ) : (
          <div
            className="d-flex justify-center align-center"
            style={{ height: "100%" }}
          >
            Please select lesson to view
          </div>
        )
      ) : (
        <div
          className="d-flex justify-center align-center"
          style={{ height: "100%" }}
        >
          No lesson found
        </div>
      )}
      <ToastMessage
        setToast={setToast1}
        toast={toast1}
        type={-1}
        title="Poor Connection"
        text="You are having poor internet connectivity, please fix your connection to have a better experience"
        seconds={5000}
      />
    </div>
  );
};
export default LessonPlayer;
