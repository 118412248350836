import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isMass: false,
    userId: localStorage.getItem("userId"),
    userDetail: null,
    schoolCode: null,
    classes: [],
    skills: [],
    schoolSkills: [],
  },
  reducers: {
    updateUserId: (state, action) => {
      localStorage.setItem("userId", action.payload);
      state.userId = action.payload;
    },
    updateUserDetail: (state, action) => {
      const detail = action.payload;
      if (detail !== null && detail !== undefined) {
        if ("class" in detail) {
          var masses = detail["class"].filter((e) => e["is_mass"] === "1");
          state.isMass = masses.length > 0;
          state.classes = detail["class"];
        } else {
          state.classes = [];
        }
        if ("skills" in detail) {
          state.skills = detail["skills"];
        } else {
          state.skills = [];
        }
        state.userDetail = detail;
        state.schoolCode = detail["school_code"];
        localStorage.setItem("user", JSON.stringify(detail));
      }
    },
    updateSchoolSkills: (state, action) => {
      state.schoolSkills = action.payload;
    },
    logout: (state) => {
      window.localStorage.clear();
      state.isMass = false;
      state.userId = null;
      state.userDetail = null;
      state.classes = [];
      state.skills = [];
      state.schoolSkills = [];
      state.schoolCode = null;
    },
  },
});

export const { updateUserId, updateUserDetail, updateSchoolSkills, logout } =
  authSlice.actions;

export default authSlice.reducer;
