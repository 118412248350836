import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLesson, updateWatchedClasses } from "../../redux/lesson-slice";
import {
  getFetchScormDetails,
  updateLessonProgress,
  updateScormProgress,
  updateScormProgressDetails,
} from "../../service/ilm-service";
import {
  classDetailsReducer,
  isLevel0Reducer,
  conductSessionReducer,
  lessonsReducer,
  playingLessonReducer,
  watchedClassesReducer,
  userDetailReducer,
} from "../../redux/reducers";
import { lessonsToCallAPI } from "../../utils/constants";
import ScormPlayerCard from "../players/scorm-player-card";

const OtherLessonPlayer = ({ grade, section }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [scormData, setScormData] = useState(null);
  const conductSession = useSelector(conductSessionReducer);
  const detail = useSelector(classDetailsReducer);
  const lesson = useSelector(playingLessonReducer);
  const lessons = useSelector(lessonsReducer);
  const watchedClasses = useSelector(watchedClassesReducer);
  const isLevel0 = useSelector(isLevel0Reducer);
  const user = useSelector(userDetailReducer);
  const [view, setView] = useState(false);

  // goto next lesson
  const gotoNextLesson = () => {
    setLoading(false);
    setScormData(null);
    var index = lessons.findIndex((e) => e["id"] === lesson["id"]);
    const nextIndex = index + 1;
    dispatch(updateWatchedClasses(lesson["id"]));
    dispatch(changeLesson(nextIndex));
  };

  // call scorm watch history & update scorm / goto next lesson
  useEffect(() => {
    try {
      if (scormData !== null) {
        if (
          !watchedClasses.includes(lesson["id"]) &&
          !loading &&
          (isLevel0 ||
            (conductSession && lessonsToCallAPI.includes(lesson.classType)))
        ) {
          setLoading(true);
          updateLessonProgress(
            detail.skillType,
            lesson.themeId,
            lesson.skillId,
            lesson.ilmId,
            100,
            lesson.id,
            lesson.classType,
            lesson.fileType,
            1,
            detail.themeCategory,
            grade,
            section
          ).then(async () => {
            await updateScormProgress(
              lesson.classType,
              detail.skillType,
              lesson.skillId,
              lesson.themeId,
              lesson.ilmId,
              lesson.id,
              grade,
              section,
              scormData
            );
            gotoNextLesson();
          });
        } else {
          gotoNextLesson();
        }
      }
    } catch (err) {
      console.error("Error updating progress:", err);
    } finally {
      setScormData(null);
      setLoading(false);
    }
  }, [scormData]);

  // api call for doc type but not supporting file
  useEffect(() => {
    setScormData(null);
    if (lesson.fileType === "ia") {
      getFetchScormDetails(lesson["ilmId"], lesson["id"])
        .then((res) => {
          if ("details" in res && "suspend_data" in res["details"]) {
            var details = res["details"];
            const cache = {
              core: {
                _children:
                  "student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,exit,session_time",
                student_id: detail["id"],
                student_name: detail["fullname"],
                lesson_location: details.lesson_location,
                credit: "credit",
                lesson_status: details.lesson_status,
                entry: details["entry"],
                lesson_mode: "normal",
                exit: details.cmi_exit,
                session_time: details.session_time,
                score: {
                  raw: details["score_raw"],
                  min: "",
                  max: "100",
                  _children: "raw",
                },
                total_time: details["total_time"],
              },
              objectives: {},
              student_data: {
                mastery_score: details["masteryscore"],
                max_time_allowed: "",
                time_limit_action: "",
              },
              student_preference: {
                audio: "",
                language: "",
                speed: "",
                text: "",
              },
              interactions: {
                interactionsData: details["interactions"],
              },
              launch_data: details.launch_data,
              suspend_data: details.suspend_data,
            };
            localStorage.setItem("cmiData", JSON.stringify(cache));
          }
        })
        .finally(() => setView(true));
    } else {
      setView(true);
    }

    // cleanup the variables
    return () => {
      setView(false);
      setScormData(null);
      updateScormProgressDetails(lesson["ilmId"], lesson["id"])
        .then(() => console.log("called while leaving successfully..."))
        .then(() => localStorage.removeItem("cmiData"));
    };
  }, [lesson]);

  return (
    <ScormPlayerCard
      view={view}
      scormKey={lesson.id}
      src={lesson.classSource}
      title={lesson.className}
      handleScormEvent={(e) => {
        console.log(e);
        setScormData(e);
      }}
    />
  );
};
export default OtherLessonPlayer;
