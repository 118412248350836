import { useDispatch, useSelector } from "react-redux";
import { changeLesson } from "../../redux/lesson-slice";
import LinearProgress from "../progress/linear-progress";
import LessonCompletedIcon from "../../assets/lesson/lesson-watched.png";
import LessonPlayingIcon from "../../assets/lesson/lesson-playing.png";
import LessonUnlockedIcon from "../../assets/lesson/lesson-unlocked.png";
import LessonLockedIcon from "../../assets/lesson/lesson-locked.png";
import SupportDoc from "../../assets/lesson/support-doc.png";
import {
  classDetailsReducer,
  conductSessionReducer,
  lessonsReducer,
  playingLessonReducer,
  progressPercentageReducer,
  watchedClassesReducer,
} from "../../redux/reducers";
import { useEffect } from "react";
import { updateTabs } from "../../redux/lesson-slice";

const LessonList = () => {
  const dispatch = useDispatch();
  const conductSession = useSelector(conductSessionReducer);
  const detail = useSelector(classDetailsReducer);
  const lessons = useSelector(lessonsReducer);
  const playing = useSelector(playingLessonReducer);
  const completed = useSelector(watchedClassesReducer);
  const progressPercentage = useSelector(progressPercentageReducer);

  useEffect(() => {
    dispatch(
      updateTabs({
        classes: true,
        minCount: completed.length,
        maxCount: lessons.length,
      })
    );
  }, [completed, lessons]);

  return (
    <div className="classroom__right radius6 p3 d-flex flex-col gap3 elevation3">
      <div className="depth3 radius3 p3 d-flex flex-col gap3">
        <div className="d-flex gap3">
          <div
            className="radius2 p1 elevation2"
            style={{ height: "42px", width: "42px" }}
          >
            <div
              className="radius1 depth3 d-flex justify-center align-center large__header5__bold"
              style={{
                background:
                  "linear-gradient(90deg, rgba(254, 211, 133, 0.5) 0%, rgba(248, 247, 254, 0.5) 43.27%, rgba(192, 184, 250, 0.5) 87.53%)",
                color: "#3E444F",
              }}
            >
              1
            </div>
          </div>
          <div>
            <div className="large__label1__bold" style={{ color: "#3A2CB8" }}>
              ILM Completion Progress
            </div>
            <div className="d-flex gap2">
              <div className="large__label2__regular">Class Duration</div>
              <div className="large__label2__bold">
                {detail != null ? detail.duration : "--:--"}Mins
              </div>
            </div>
          </div>
        </div>
        {conductSession && (
          <div className="d-flex flex-col gap1">
            <div
              className="d-flex px-1"
              style={{ fontFamily: "Manrope", fontSize: "14px" }}
            >
              <div style={{ fontWeight: "700", color: "#3F3782" }}>
                {completed.length}{" "}
              </div>
              <div style={{ fontWeight: "400", color: "#5247A8" }}>
                {" "}
                / {lessons.length} Classes
              </div>
            </div>
            <LinearProgress percent={progressPercentage} />
          </div>
        )}
      </div>

      <div className="classroom__playlist radius3 d-flex flex-col gap3">
        {lessons.length > 0 ? (
          lessons.map((lesson, i) => (
            <div
              key={i}
              className={`lesson__card d-flex align-center gap3 ${
                playing !== null && playing.id === lesson.id
                  ? "active__lesson"
                  : ""
              }`}
              onClick={() => {
                if (lesson.status !== -1 || !conductSession) {
                  dispatch(changeLesson(i));
                }
              }}
              style={{
                cursor:
                  lesson.status !== -1 || !conductSession
                    ? "pointer"
                    : "not-allowed",
                opacity: lesson.status !== -1 || !conductSession ? "1" : ".75",
              }}
            >
              <div className="lesson__index d-flex align-center justify-center">
                {lesson.classType !== "supporting_file" ? (
                  i + 1
                ) : (
                  <img
                    style={{
                      width: "14px",
                      height: "14px",
                      objectFit: "contain",
                    }}
                    src={SupportDoc}
                    alt={lesson.className}
                  />
                )}
              </div>
              <div className="fl1 d-flex flex-col align-start">
                {playing !== null &&
                  playing.id === lesson.id &&
                  lesson.classType !== "supporting_file" && (
                    <div
                      className="large__label3__regular"
                      style={{ color: "#6271FF", lineHeight: "10px" }}
                    >
                      Now Playing
                    </div>
                  )}
                {conductSession &&
                  playing !== null &&
                  !completed.includes(lesson.id) &&
                  playing.id !== lesson.id &&
                  lesson.classType !== "supporting_file" && (
                    <div
                      className="large__label3__regular"
                      style={{ color: "#82868E", lineHeight: "10px" }}
                    >
                      Complete previous class
                    </div>
                  )}
                <div
                  className="large__body__bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {lesson.className}
                </div>
                {lesson.classType !== "supporting_file" && (
                  <div
                    className="lesson__duration large__caption3__bold"
                    style={{
                      boxShadow:
                        playing !== null && playing.id === lesson.id
                          ? "2px 2px 4px -1px #F4A71D91"
                          : "none",
                    }}
                  >
                    {lesson.classDuration} Mins
                  </div>
                )}
              </div>
              {conductSession && lesson.classType !== "supporting_file" && (
                <div className="lesson__playing__status d-flex align-center justify-center">
                  <img
                    alt="Lesson Status"
                    src={
                      lesson.status === 0
                        ? LessonPlayingIcon
                        : lesson.status === 1
                        ? LessonCompletedIcon
                        : lesson.status === 2
                        ? LessonUnlockedIcon
                        : LessonLockedIcon
                    }
                    style={{ height: "32px", width: "32px" }}
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <div
            className="d-flex align-center justify-center"
            style={{ height: "100%", minHeight: "10vh" }}
          >
            No lesson found
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonList;
