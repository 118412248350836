import { PictureInPicture2, Heart, LifeBuoy } from "lucide-react";
import {
  CERTIFICATION_PROGRAM_PATH,
  DASHBOARD_PATH,
  EVENTS_PATH,
  PROJECTS_PATH,
  SKILL_PROGRAM_ILMS_PATH,
  WATCH_HISTORY_PATH,
  FAVOURITES_PATH,
  SUPPORT_VIDEOS_PATH,
  UPCOMING_EVENTS_PATH,
  ENROLLED_EVENTS_PATH,
  RECORDING_EVENTS_PATH,
  PENDING_PROJECTS_PATH,
  OVERVIEW_PATH,
  LOGIN_DETAIL_PATH,
  STUDENTS_REPORT_PATH,
  USER_TYPE_SUMMARY_PATH,
  SKILLS_COMPLETION_PATH,
  POPULAR_ILMS_PATH,
  PERFORMANCE_TREND_PATH,
  USER_TREND_PATH,
  TOP_PERFOMERS_PATH,
  USAGE_SUMMARY_PATH,
  USER_STATS_PATH,
  SELECTED_SKILLS_PATH,
  SCHOOL_OPTED_SKILLS_PATH,
  STUDENT_ENROLLED_SKILLS_PATH,
  SKILL_WISE_REPORT_PATH,
  STUDENT_WISE_REPORT_PATH,
} from "../utils/route-constants";
// navigation menus
export const NAVBAR_MENUS = [
  { title: "ILMs", path: SKILL_PROGRAM_ILMS_PATH },
  { title: "Events", path: EVENTS_PATH + "/" + UPCOMING_EVENTS_PATH },
  { title: "Dashboard", path: DASHBOARD_PATH },
  { title: "Projects", path: PROJECTS_PATH + "/" + PENDING_PROJECTS_PATH },
  {
    title: "Certification Program",
    path: CERTIFICATION_PROGRAM_PATH,
    comingSoon: true,
  },
];

// profile navigation menus
export const PROFILE_MENUS = [
  {
    title: "Watch History",
    icon: <PictureInPicture2 color="#7364EC" />,
    path: WATCH_HISTORY_PATH,
  },
  {
    title: "Favourites",
    icon: <Heart color="#7364EC" />,
    path: FAVOURITES_PATH,
  },
  {
    title: "FAQ & Support",
    icon: <LifeBuoy color="#7364EC" />,
    path: SUPPORT_VIDEOS_PATH,
  },
];

// events tabs
export const EVENTS_TABS = [
  { title: "Upcoming Events", path: UPCOMING_EVENTS_PATH },
  { title: "Enrolled Events", path: ENROLLED_EVENTS_PATH },
  { title: "Events Recordings", path: RECORDING_EVENTS_PATH },
];

// history & favourites
export const HISTORY_FAVOURITE = [
  { title: "Favourites", path: `/${FAVOURITES_PATH}` },
  { title: "Watch History", path: `/${WATCH_HISTORY_PATH}` },
];

// grade dashboard menus
export const GRADE_DASHBOARD_MENU = [
  { title: "Overview", path: OVERVIEW_PATH },
  { title: "Login Details", path: LOGIN_DETAIL_PATH },
  {
    title: "Student Reports",
    path: STUDENTS_REPORT_PATH + "/" + SKILL_WISE_REPORT_PATH,
  },
  { title: "User Type Summary", path: USER_TYPE_SUMMARY_PATH },
  { title: "Skill Completion", path: SKILLS_COMPLETION_PATH },
  { title: "Popular ILMs", path: POPULAR_ILMS_PATH },
  { title: "Performace Trend", path: PERFORMANCE_TREND_PATH },
  { title: "User Trends", path: USER_TREND_PATH },
  { title: "Events", path: EVENTS_PATH },
];

// school dashboard menus
export const SCHOOL_DASHBOARD_MENU = [
  { title: "Overview", path: OVERVIEW_PATH },
  { title: "Login Details", path: LOGIN_DETAIL_PATH },
  { title: "Top Performers", path: TOP_PERFOMERS_PATH },
  { title: "Usage Summary", path: USAGE_SUMMARY_PATH },
  { title: "User Stats", path: USER_STATS_PATH },
  {
    title: "Selected Skills",
    path: SELECTED_SKILLS_PATH + "/" + SCHOOL_OPTED_SKILLS_PATH,
  },
];

// tabls headers
export const LOGIN_DETAILS_HEADER = [
  { title: "Sl No.", key: "sln" },
  { title: "Student Name", key: "fullname" },
  { title: "Log In Status", key: "logged_in_status" },
];
export const SKILL_WISE_REPORT_HEADER = [
  { title: "Student Name", key: "student_name" },
  { title: "Skill Program Name", key: "skill_program_name" },
  { title: "ILMs", key: "ilms_watched" },
  { title: "IAs", key: "ias_completed" },
  { title: "Projects Submitted", key: "proj_submitted" },
  { title: "Projects Approved", key: "proj_approved" },
];
export const STUDENTS_REPORT_HEADER = [
  { title: "Student Name", key: "fullname" },
  { title: "Rank Point", key: "rank_points" },
  { title: "Skill Program Name", key: "skill_name" },
  { title: "ILM Name", key: "ilm_name" },
  { title: "Watch Status", key: "watch_status" },
  { title: "IA Status", key: "ia_status" },
  { title: "Project Submission Status", key: "proj_submission_status" },
  { title: "Project Approval Status", key: "proj_approval_status" },
];
export const USER_TYPE_SUMMARY_HEADER = [
  { title: "Sl No.", key: "sln" },
  { title: "Student Name", key: "name" },
  { title: "User Status", key: "user_status" },
];
export const SKILLS_COMPLATION_HEADER = [
  { title: "Sl No.", key: "sln" },
  { title: "Student Name", key: "student_name" },
  { title: "Skill Name", key: "skill_name" },
  { title: "Date", key: "created_date" },
];
export const POPULAR_ILMS_HEADER = [
  { title: "Sl No.", key: "sln" },
  { title: "ILM Name", key: "ilm_name" },
  { title: "Skill Program Name", key: "program_name" },
  { title: "No. of Views", key: "no_of_views" },
];
export const EVENTS_HEADER = [
  { title: "Sl No.", key: "sln" },
  { title: "Attendee Name", key: "name" },
];
export const TOP_PERFOMERS_HEADER = [
  { title: "Sl No.", key: "sln" },
  { title: "Grade", key: "id" },
  { title: "Name", key: "name" },
  { title: "Rank", key: "username" },
  { title: "Rank Point", key: "id" },
];
export const USAGE_SUMMARY_HEADER = [
  { title: "Grade", key: "class_section" },
  { title: "ILMs Watch Completed", key: "ilm_watch_completed" },
  { title: "IAs", key: "ia_completed_count" },
  { title: "Projects Submitted", key: "projects_submitted" },
  { title: "Projects Approved", key: "project_approved" },
  { title: "ILMs Completed", key: "ilm_completed" },
  { title: "Skills Completed", key: "skills_completed" },
];
export const SELECTED_OPTED_SKILLS_HEADER = [
  { title: "Sl No.", key: "sln" },
  { title: "Grade Level", key: "grade_level" },
  { title: "Skill Name", key: "skill_name" },
  { title: "Skill Option", key: "skill_option" },
];

export const STUDENT_ENROLLED_SKILLS_HEADER = [
  { title: "Grade", key: "grade" },
  { title: "Skill Name", key: "skill" },
  { title: "No. of Student Enrolled", key: "no_of_students_enrolled" },
];

// events tabs
export const STUDENTS_REPORT_TABS = [
  { title: "Skill Wise Summary", path: SKILL_WISE_REPORT_PATH },
  { title: "Details", path: STUDENT_WISE_REPORT_PATH },
];

// events tabs
export const SELECTED_SKILLS_TABS = [
  { title: "School Opted Skills", path: SCHOOL_OPTED_SKILLS_PATH },
  { title: "Student Enrolled Skills", path: STUDENT_ENROLLED_SKILLS_PATH },
];

// months list
export const monthsList = [
  { title: "January", short: "JAN" },
  { title: "February", short: "FEB" },
  { title: "March", short: "MARCH" },
  { title: "April", short: "APRIL" },
  { title: "May", short: "MAY" },
  { title: "June", short: "JUNE" },
  { title: "July", short: "JULY" },
  { title: "August", short: "AUG" },
  { title: "September", short: "SEP" },
  { title: "October", short: "OCT" },
  { title: "November", short: "NOV" },
  { title: "December", short: "DEC" },
];

// grade parsing
export const getParsedGrade = (grade) => {
  switch (grade) {
    case "1":
      return "I";
    case "2":
      return "II";
    case "3":
      return "III";
    case "4":
      return "IV";
    case "5":
      return "V";
    case "6":
      return "VI";
    case "7":
      return "VII";
    case "8":
      return "VIII";
    case "9":
      return "IX";
    case "10":
      return "X";
    default:
      return grade;
  }
};

// grade parsing
export const getReverseParsedGrade = (grade) => {
  switch (grade) {
    case "I":
      return "1";
    case "II":
      return "2";
    case "III":
      return "3";
    case "IV":
      return "4";
    case "V":
      return "5";
    case "VI":
      return "6";
    case "VII":
      return "7";
    case "VIII":
      return "8";
    case "IX":
      return "9";
    case "X":
      return "10";
    default:
      return grade;
  }
};

// file type fomats
export const videoFilesFomatList = [
  "video_file",
  "project_video",
  "mp4",
  "mov",
  "mkv",
  "wmv",
  "avi",
  "avchd",
  "flv",
  "f4v",
  "swf",
  "webm",
  "m4v",
];
export const iaFilesFomatList = [
  "zip",
  "",
  "rar",
  "tar",
  "ia",
  "scorm",
  "scorms_file",
];
export const docFilesFormatList = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "csv",
  "ppt",
  "pptx",
  "txt",
  "PDF",
  "DOC",
  "DOCX",
  "XLS",
  "XLSX",
  "CSV",
  "PPT",
  "PPTX",
  "TXT",
];
export const imgFilesFomatList = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "JPG",
  "JPEG",
  "PNG",
  "GIF",
];
export const audioFilesFormat = [
  "mp3",
  "m4a",
  "flac",
  "wav",
  "wma",
  "aac",
  "pcm",
  "aiff",
  "ogg",
];

// get source file path
export function getFileSoucePath(type, str) {
  if (str !== null && str !== "null" && typeof str !== "undefined") {
    const path = String(str).trim();
    const finalPath = (path.includes("ulipsutest-cdn") || path.includes("ulipsu-kidvento-production-cdn"))
      ? `/cdn${path.split(".com")[1]}`
      : path;
    if (iaFilesFomatList.includes(type)) {
      return finalPath.replace(".zip", "/index_lms.html");
    }
    return finalPath;
  }
  return null;
}

// class_type = intro/conclusion/ia/project/supporting_file/lesson
// lesson_type = video/ia/doc/image

// get file type
export function getFileType(type) {
  var res = "doc";
  if (videoFilesFomatList.includes(type)) res = "video";
  else if (iaFilesFomatList.includes(type)) res = "ia";
  else if (imgFilesFomatList.includes(type)) res = "image";
  return res;
}

// get formatted duration
export function getFormattedDuration(str) {
  if (!str) return "00:00";

  return str.includes(".")
    ? str.padStart(2, "0").replace(".", ":")
    : `${str.padStart(2, "0")}:00`;
}

// lessons to call api
export const lessonsToCallAPI = [
  "intro",
  "introduction",
  "conclusion",
  "lesson",
  "recap",
];

// graph tables
export const graphTableRoutes = ["school/login-details", "school/user-stats"];
