import { Maximize } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { docFilesFormatList, imgFilesFomatList } from "../../utils/constants";
import {
  getFileExtension,
  removeDuplicatesById,
  removeSimilarDuplicatesById,
  toggleFullScreen,
} from "../../utils/utils";

const ScormPlayerCard = ({
  view = true,
  src,
  scormKey,
  title,
  handleScormEvent,
  quest,
}) => {
  const iframeILM = useRef(null);
  const iframeParent = useRef();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [iframeSrc, setIframeSrc] = useState(src);
  const [timeoutError, setTimeoutError] = useState(false);
  const scormAPIBaseURL = window.location.origin;

  // Timeout for loading
  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setTimeoutError(true);
        setLoading(false);
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [loading]);

  // Set iframe source based on file type
  useEffect(() => {
    if (!src) return; // Don't do anything if src is not yet available
    const fileExtension = getFileExtension(src);
    if (docFilesFormatList.includes(fileExtension)) {
      const replaced = src.replace(
        "/cdn",
        "https://ulipsutest-cdn.ulipsu.com"
      );
      const googleViewUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
        replaced
      )}&embedded=true#toolbar=0&scrollbar=0`;
      setIframeSrc(googleViewUrl);
    } else {
      setIframeSrc(src);
    }
  }, [src]);

  useEffect(() => {
    let isCalling = false;
    let previousCmiData = localStorage.getItem("cmiData");
    let timeoutId = null;

    // Function to handle localStorage changes
    const handleStorageChange = (event) => {
      if (event.key !== "cmiData") return;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const newCmiData = JSON.parse(event.newValue);
        if (
          previousCmiData === event.newValue ||
          newCmiData.core.session_time === "" ||
          newCmiData.core.session_time ===
            JSON.parse(previousCmiData)?.core.session_time
        ) {
          return;
        }

        let interactions = [];
        if (
          newCmiData.interactions &&
          newCmiData.interactions.interactionsData
        ) {
          interactions = newCmiData.interactions.interactionsData.filter(
            (obj) => Object.keys(obj).length !== 0
          );
        }
        const unique = removeDuplicatesById(interactions);
        interactions = removeSimilarDuplicatesById(unique, "0");
        const updated = {
          ...newCmiData,
          interactions: {
            childArray: interactions,
          },
        };
        if (
          !loading &&
          !isCalling &&
          updated.core.lesson_status === "completed"
        ) {
          isCalling = true;
          handleScormEvent(updated);
        } else {
          isCalling = false;
        }
        previousCmiData = event.newValue;
      }, 300);
    };

    // Attach the event listener for 'storage' events
    window.addEventListener("storage", handleStorageChange);
    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div
      className={`${quest ? "quest_container" : "player__container"}`}
      ref={iframeParent}
    >
      {/* Conditionally render image or iframe */}
      {iframeSrc &&
        !timeoutError &&
        (imgFilesFomatList.includes(getFileExtension(iframeSrc)) ? (
          <img
            key={scormKey}
            src={iframeSrc}
            alt={title}
            onLoad={() => setLoading(false)}
            onError={() => {
              setError(true);
              setLoading(false);
            }}
            style={{ width: "100%", height: "100%", objectFit: "fill" }}
          />
        ) : (
          view && (
            <>
              <iframe
                id="apiWrapper"
                src=""
                name="API"
                width="0"
                height="0"
                title="API"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: "-1",
                }}
                srcDoc={`<html>
                    <head>
                        <script src='https://code.jquery.com/jquery-latest.min.js'></script>
                        <script src='${scormAPIBaseURL}/scorm-api.js'></script>
                        </head>
                    <body>.</body>
                </html>`}
              />
              <iframe
                key={scormKey}
                title={title}
                src={iframeSrc}
                ref={iframeILM}
                onLoad={() => setLoading(false)}
                onError={() => {
                  setError(true);
                  setLoading(false);
                }}
                style={{ width: "100%", height: "100%" }}
              />
            </>
          )
        ))}

      {/* video source is empty or null */}
      {(src === null || src.length <= 0 || error || timeoutError) && (
        <div
          className="d-flex flex-col align-center justify-center elevation2"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          {timeoutError ? (
            <span>Failed to load the document. Please try again!</span>
          ) : (
            <>
              <span>Couldn't load media. Please try again! or</span>
              <span>Contact Support</span>
            </>
          )}
        </div>
      )}
      {/* Loader */}
      {loading && !error && !timeoutError && (
        <div className="player-loader">
          <div className="spinner" />
        </div>
      )}
      {/* go to full screen */}
      {iframeSrc && !timeoutError && (
        <div
          className="d-flex align-center justify-center radius1 p1"
          style={{
            position: "absolute",
            bottom: "16px",
            right: "16px",
            background: "rgba(0, 0, 0, .4)",
            cursor: "pointer",
          }}
          onClick={() => toggleFullScreen(iframeParent)}
        >
          <Maximize size={20} color="white" />
        </div>
      )}
    </div>
  );
};

export default ScormPlayerCard;
